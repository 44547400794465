export const NETWORK_NAME = 'Hedera Mainnet'
export const NAT_SYMBOL = 'HBAR'
export const WNAT_ADDRESS = '0x0000000000000000000000000000000002dfa5b2'.toLowerCase()
export const EXPLORER_LINK_BASE = 'https://hashscan.io/mainnet'
export const EXPLORER_NAME = 'HashScan'
export const SUBGRAPH_HOST = 'https://graph-hedera-main.pangolin.network'
export const SUBGRAPH_NAME_PANGOLIN = 'pangolin'
export const SUBGRAPH_NAME_BLOCKS = 'blocks'
export const SWAP_FEE_TO_LP = 0.003
export const SYMBOL_MAX_DISPLAY_LENGTH = 9
export const NAME_MAX_DISPLAY_LENGTH = 16
export const SOCIAL_LINK_TWITTER = 'https://twitter.com/Pangolin_Hedera'
export const PANGOLIN_TOKEN_IMAGE_REPO_BASE = 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/295'
export const SOCIAL_LINK_DISCORD = 'https://discord.com/invite/jC2gJneDAp'
export const SUPPORTED_TOKEN_LIST_URLS = [
  'https://raw.githubusercontent.com/pangolindex/tokenlists/main/pangolin.tokenlist.json',
]
export const TOKEN_BLACKLIST = [
  '0x00000000000000000000000000000000001d7d98'.toLowerCase(),
]
export const PAIR_BLACKLIST = [
]

/*
 * Mapping between address and CoinGecko coin id
 * Using CoinGecko API: https://api.coingecko.com/api/v3/coins/list
 */
export const COIN_ID_MAP = {
  ['0x00000000000000000000000000000000001a8837'.toLowerCase()]: 'hedera-hashgraph',
  ['0x00000000000000000000000000000000001a88b2'.toLowerCase()]: 'pangolin-hedera',
  ['0x00000000000000000000000000000000000cba44'.toLowerCase()]: 'hbarx',
  ['0x00000000000000000000000000000000000ec585'.toLowerCase()]: 'headstarter',
}

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  ALL_TIME: 'All time',
}
export const PAIR_CHART_VIEW_OPTIONS = {
  VOLUME: 'Volume',
  LIQUIDITY: 'Liquidity',
  RATE0: 'Rate 0',
  RATE1: 'Rate 1',
}
